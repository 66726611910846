<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
				<h5> Operational Expenses Report ({{dataList.total}})</h5>
			</CCol>
	     		 <CCol lg="6">
		      		 <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
						:aria-expanded="showCollapse ? 'true' : 'false'"
						aria-controls="collapse1"
						@click="showCollapse = !showCollapse"
						color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i>
					</CButton>
			        <!-- <CButton 
							v-if="config.getPermission('opex_reports').download"
							size="sm" style="margin-right: 10px" 
							color="btn btn-outline-primary"
							@click="download"
							class="float-lg-right">
							<i class="fa fa-download">
							</i> Download
					</CButton> -->
					<CButton 
						v-if="config.getPermission('opex_reports').download"
						size="sm" style="margin-right: 10px" 
						color="btn btn-outline-primary"
						@click="() => { showModalDownloadFilter = true }"
						class="float-lg-right">
						<i class="fa fa-download">
						</i> Download
					</CButton>
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @opex-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	      	
		      <CCol lg="12" >  
				<div class="table-responsive-sm table-responsive-md table-responsive-lg">
	      		<CDataTable
				  :loading="isLoading"
			      	:items="dataList.data"
			      	:fields="fields"
					hover
					striped
					outlined
					
			    >  
				<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:capitalize;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>

			    </CDataTable> 
			    <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(dataList.total / 20)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        /> 
			</div>
	      	</CCol>
	    </CRow> 
		 
		</CCard>
		<download-filter :filters="filter" :booking_no="booking_no" :showModalDownloadFilter="showModalDownloadFilter" @closeModalDownloadFilter="closeModalDownloadFilter"/>
	</div>
</template>
<script> 
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './opex_report_search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'; 
import moment from 'moment';
import '../style.css';
import downloadFilter from './opex_filter_report.vue';


export default {
	mounted(){  
		this.getData();  
	},
	data(){
		return{
			config,
			isLoading: false,
			dataList: [],  

			fields: [
				{
					key: 'booking_no', 
					label: 'Booking No.'
				},
				{
					key: 'delivery_date', 
					label: 'Delivery Date'
				},
				{
					key: 'customer_name', 
					label: 'Client'
				}, 
				{
					key: 'driver_name', 
					label: 'Driver'
				}, 
				{
					key: 'depot', 
					label: 'Depot'
				}, 
				{
					key: 'destination', 
					label: 'Destination'
				}, 		
				
				{
					key: 'budget_amount', 
					label: 'TF/Budget Amount'
				},

				
				{
					key: 'total_amount', 
					label: 'Total Amount'
				},
				{
					key: 'cash_returned', 
					label: 'Cash Returned'
				},
				
	

				
			
			],
			currentPage: 1,  


			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				depot_id: '',
				booking_no : '',
				customer_id : '',
				destination_id : '',
				delivery_date_to: moment(new Date())._d,
            	delivery_date_from: moment(new Date()).subtract(1, 'weeks')._d, 
			},
			showModalDownloadFilter:false,
			booking_no:null,
		}
	},
	name: 'Tables',
	components: { Search, Datepicker, vSelect, downloadFilter},
	methods: {
		getBadge (status) {
    	return status === 'Paid' ? 'success'
			 : status === 'Recovered' ? 'primary'
             : status === 'Pending' ? 'danger' : 'info'
    },
		reloadData(){
			this.getData();  
		},
		updatePage(data){
			this.currentPage = data;
			this.getData();
		},   

	
	    search(event){
	    	this.filter = event; 
	    	this.getData();  
	    }, 
		
	    getData(){  

	     	this.isLoading=true;

		 	let {customer_id, booking_no,depot_id, destination_id, delivery_date_from, delivery_date_to} = this.filter;
		 	delivery_date_to = delivery_date_to ? moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000 : moment(new Date(), "MM-DD-YYYY").startOf('day').valueOf() / 1000;
			delivery_date_from = delivery_date_from ? moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000 : moment(new Date(), "MM-DD-YYYY").subtract(1, 'weeks').valueOf() / 1000;

			
	    	axios.get(`${config.api_path}/booking-expenses/opex-report-list`,{
				params : {
					page : this.currentPage,
					limit : 20,
					customer_id,
					destination_id,
					depot_id,
					booking_no,
					delivery_date_from,
					delivery_date_to,
					company_id : config.global_user_data.company_id
				}
			})
	    	.then(response => {
	    		this.dataList = response.data.data; 
	    		this.dataList.data = this.dataList.data.map((value, index)=>{ 
	    			value.booking_no = value.booking.booking_no;
	    			value.customer_name = value.booking.customer.customer_name;
					value.destination = value.booking.destination ? value.booking.destination.setting_name ?? '' : '';
					value.depot = value.booking.depot ? value.booking.depot.setting_name ?? '' : '';
	    			value.delivery_date = value.booking.delivery_date ?  moment.unix(value.booking.delivery_date).format('MMMM DD, YYYY') : '';
					value.total_amount = value.actual_amount ?? '0.00';
					value.budget_amount = value.budget_amount ?? '0.00';
					value.cash_returned = value.cash_returned ?? '0.00';
					value.driver_name = value.booking.driver ? value.booking.driver.name : '';

	    			return value;
	    		}); 

				console.log(this.dataList.data)
	    	})
				.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    

	   

	    async download(){ 
	    	let {customer_id, booking_no, destination_id, depot_id,delivery_date_from, delivery_date_to} = this.filter;
			delivery_date_to = delivery_date_to ?? moment(new Date(), "MM-DD-YYYY").startOf('day').valueOf() / 1000;
			delivery_date_from = delivery_date_from ?? moment(new Date(), "MM-DD-YYYY").subtract(1, 'weeks').valueOf() / 1000;
	    	
			this.$showLoading(true)
	    	await axios.get(`${config.api_path}/booking-expenses/opex-report-download`,{
				params : {
					customer_id,
					destination_id,
					depot_id,
					booking_no,
					delivery_date_from,
					delivery_date_to,
					company_id : config.global_user_data.company_id
				}
			})
	    	.then(response=>{
				this.$showLoading(true)
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
				this.$showLoading(false)
				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
				
	    	})
			.catch(err => {
				this.$showLoading(false)
			})

	    },
		closeModalDownloadFilter(status){
			this.showModalDownloadFilter = status;
		},
 	}
}
</script>
