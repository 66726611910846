<template>
    <CModal title="Download Report" :show.sync="showModalDownloadFilter_" color="info" size="md" @close="() => { showModalDownloadFilter_ = false }">
        <form @submit.prevent="approve">
			<div class="pl-3 pr-3 pb-3">
                <h5>Choose a filter to download a report</h5>
                <br>
				<CRow class="mb-2">
                    
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Delivery Date From</label>
                        <Datepicker v-model="filter.delivery_date_from" input-class="form-control" placeholder="Delivery Date from" />
					</CCol>
                    <CCol lg="12" class="mb-3">
                        <label class="mb-1">Delivery Date To</label>
                        <Datepicker v-model="filter.delivery_date_to" input-class="form-control" placeholder="Delivery Date to" />
                    </CCol>
                    <CCol lg="12">
						<label class="mb-1">Booking No.</label>
						<CInput v-model="filter.booking_no"/>
					</CCol>
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Customer</label>
                        <v-select label="customer_name" :options="customer_list.data" :reduce="item => item.id" v-model="filter.customer_id" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Depot</label>
						<v-select label="setting_name" :options="depot_list.data" :reduce="item => item.id" v-model="filter.depot_id" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Destination</label>
						<v-select label="setting_name" :options="destination_list.data" :reduce="item => item.id" v-model="filter.destination_id" placeholder="-Select-"></v-select>
					</CCol>
                    
                    <CCol lg="12" class="mb-3">
                        <h6 class="text-danger"> {{error_msg}}</h6>
                    </CCol>
				</CRow>
            </div>
        </form>
        <div slot="footer" class="w-100">
            <!-- <CButton class="ml-1 mr-1 float-right" size="sm" color="info" @click="download"><i class="fa fa-file"></i> Generate Report</CButton>
            <CButton class="ml-1 mr-1 float-right" size="sm" color="light" @click="clear">Clear</CButton> -->
            
            <CButton class="float-right" size="sm" color="success" @click="download" v-if="!processing"><i class="fa fa-file"></i> Generate Report</CButton>
            <a :href="link_download" v-if="ready_download"><i class="fa fa-file"></i> Download Report</a>
            <CButton color="primary" disabled v-if="processing && !ready_download">
                <CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                Generating Report. Please wait ...
            </CButton>
            <CButton class="ml-1 mr-1 float-right" size="sm" color="light" @click="clear" v-if="!processing"><i class="fa fa-close"></i> Clear</CButton>
        </div>
    </CModal>
</template>

<script>
import config from '../config.js';
import axios from '../axios';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';  
import Datepicker from 'vuejs-datepicker';
import 'vue-select/dist/vue-select.css';
export default {
    components: { vSelect, Datepicker },
    mounted() {
        
    },
    props: {
        showModalDownloadFilter: {required: true},
        filters: {}
        // booking_no: {required: true},
    },
    data() {
        return {
            showModalDownloadFilter_:false,
            filter: {
                booking_no :'',
				customer_id: '',
				depot_id:'',
				destination_id:'',
				delivery_date_to: moment(new Date())._d,
                delivery_date_from: moment(new Date()).subtract(1, 'weeks')._d, 
			},
            destination_list :[],
            depot_list:[],
            customer_list:[],
            no_filter:false,
            currentPage:1,
            error_msg: '',
            processing:false,
            ready_download:false,
            link_download:'',
            report_downloaded: false
        }
    },
    methods: {
        async download() {
            let msg =  'Please select a filter before downloading the report.';
            this.report_downloaded = false;
            console.log(this.dataValidate() , this.dateRangeValidate())
            if(this.dataValidate() && this.dateRangeValidate()){
           
                var date_from = this.filter.delivery_date_from;
                if(date_from != ""){
                    date_from = moment(date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
                }
                else{
                    date_from = '-'
                }
                var date_to = this.filter.delivery_date_to;
                if(date_to != ""){	
                    date_to = moment(date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
                    date_to = date_to + 86399; 
                }else{
                    date_to = '-'
                }
                this.ready_download = false;
                this.processing = true;
                this.no_filter = false;
                // this.$showLoading(true)
                // await axios.get(config.api_path+'/booking-expenses/opex-report-download?'+
                // '&company_id='+config.global_user_data.company_id+
                // '&customer_id='+this.filter.customer_id+
                // '&depot_id='+this.filter.depot_id+
                // '&destination_id='+this.filter.destination_id+
                // '&booking_no='+this.filter.booking_no+
                // '&date_from='+date_from+
                // '&date_to='+date_to
                await axios.post(`${config.api_path}/booking-expenses/opex-report-download`, {
                   
                        company_id:config.global_user_data.company_id,
                        customer_id:this.filter.customer_id,
                        depot_id:this.filter.depot_id,
                        destination_id:this.filter.destination_id,
                        booking_no:this.filter.booking_no,
                        date_from:date_from,
                        date_to:date_to
                   
                }
                ).then(response=>{
                    this.link_download = config.main_path.replace('/index.php', '')+'/'+response.data.file
                    this.watchDownload(response.data.file);
                    this.error_msg = '';
                    // setTimeout(() => {
                    //     this.$showLoading(false)
                    //     window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file)
                      
                    // }, 3000);
                    // setTimeout(() => {
                    //     axios.get(`${config.api_path}/remove-file`,{
                    //         params : {
                    //             filename : response.data.file
                    //         }
                    //     })	
                    // }, 5000);
                })
                .catch(err => {
                    // this.$showLoading(false)
                    this.no_filter = true;
                    this.ready_download = false;
                    this.processing = false;
                });
            }
            else{
                msg = 'Please input delivery date from and delivery date to.'
                this.no_filter = true;
                this.ready_download = false;
                this.processing = false;
                this.error_msg = msg;
                this.dateFilterValidatePrompt();
            }
        },
        getCustomer() {
            axios.get(config.api_path + '/reference/customer-list', {
                params: {show_all: true}
            }).then(response => {
                this.customer_list = response.data;
            });
        },
        getDepot(){
            axios.get(config.api_path+'/reference/settings-list', {
                params:{ setting_type:'depot'}
             }) .then(response => {
                this.depot_list = response.data;
            });
        },
        getDestination(){
            axios.get(config.api_path+'/reference/settings-list', {
                params:{ setting_type:'destination'}
             }) .then(response => {
                this.destination_list = response.data;
            });
        },
        clear(){
            this.filter.booking_no = '';
            this.filter.customer_id = '';
            this.filter.depot_id = '';
            this.filter.destination_id = '';
            this.filter.delivery_date_from = moment(new Date())._d,
            this.filter.delivery_date_to = moment(new Date()).subtract(1, 'weeks')._d;
        },
        dataValidate(){
            return true;
            if(
                this.filter.booking_no || 
                this.filter.customer_id || 
                this.filter.depot_id || 
                this.filter.destination_id ||
                (this.filter.delivery_date_from && this.filter.delivery_date_to && this.dateRangeValidate)
            ){
                return true;
            }
            else{
                return false;
            }
        },
        dateRangeValidate(){
            let from = this.filter.delivery_date_from;
            let to = this.filter.delivery_date_to;
            const num_days = moment(to).diff(from, 'days');
            console.log(num_days)
            if(!from || !to){
                return false;
            }else{
                if(from && to){
                    // const start = new Date(from).setHours(0, 0, 0);
                    // const end = new Date(to).setHours(23, 59, 59);
                    // // Calculate the difference in milliseconds
                    // const differenceInMilliseconds = end - start;
                    // // Calculate the difference in week
                    // const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
                    // // Check if the difference is not greater than 1 week
                    // return differenceInDays <= 7;

                    return (num_days <= 7)
                }
                else{
                    return false;
                }
            }
        },
        dateFilterValidatePrompt(){
            let from = this.filter.delivery_date_from;
            let to = this.filter.delivery_date_to;
            const num_days = moment(to).diff(from, 'days');
            if(from && to){
                // const start = new Date(from).setHours(0, 0, 0);
                // const end = new Date(to).setHours(23, 59, 59);
                // // Calculate the difference in milliseconds
                // const differenceInMilliseconds = end - start;
                // // Calculate the difference in week
                // const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
                // // Check if the difference is not greater than 1 week
                // if(!(differenceInDays <= 7)){
                //     this.error_msg = 'Filter date should not exceed 1 week or 7 days.';
                // }

                if(num_days > 7){
                    this.error_msg = 'Filter date should not exceed 1 week or 7 days.';
                }
            }
        },
        watchDownload(file){
            const self = this;
            self.interval_import_program = setInterval(function () {
                axios.get(`${config.api_path}/watch-download`,{
                    params : {
                        filename : file
                    }
                }).then(response => {
                    if(response.data.status){
                        self.ready_download = true;
                        self.processing = false;
                        clearInterval(self.interval_import_program);
                        if(!self.report_downloaded) {
                            self.report_downloaded = true;
                            window.open(config.main_path.replace('/index.php', '')+'/'+file)
                        }
                    }
                });
            }, 1000);
        }
    },
    watch: {
        showModalDownloadFilter_: function(val){
            this.$emit('closeModalDownloadFilter', val)
        },
        showModalDownloadFilter: function(){
            this.showModalDownloadFilter_ = this.showModalDownloadFilter;

            if(this.processing || this.ready_download)
                return;

            if(!this.customer_list?.data?.length)
                this.getCustomer();
            if(!this.depot_list?.data?.length)
                this.getDepot();
            if(!this.destination_list?.data?.length)
                this.getDestination();

            this.download()
        },
        filters: {
            deep: true,
            handler() {
                this.filter = {...this.filters}
                this.ready_download = false;
            }
        }
    },
}
</script>

<style>
.modal-header {
    background-color: white !important;
    
}
.modal-title {
    color: black;
}
</style>
